import React, { Component } from 'react';
import Home from './Home';
import privacyPolicy from './Page/privacidad';
import serverPolicy from './Page/servicio';
import ErrorInfo from './Page/ErrorInfo';
import { HashRouter as Router, Route } from "react-router-dom";

class App extends Component {
  render() {
    console.log('----App----')
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacidad" component={privacyPolicy} />
        <Route exact path="/servicio" component={serverPolicy} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
      </Router>
    );
  }
}

export default App;
